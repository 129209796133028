.loader {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.food-loader {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.loader-content {
  margin-top: 25px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  font-weight: bold;
}

#spinner:before,
#spinner:after {
  content: "";
  position: absolute;
  top: -7px;
  left: -7px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 7px solid transparent;
  border-top-color: var(--mediumGrey);
}

#spinner:before {
  z-index: 100;
  animation: spin 1s infinite;
}

#spinner:after {
  border: 7px solid #fff;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.central-icon {
  width: 170px;
  margin: 27px 0px 0px 16px;
  -webkit-animation: zoom 2s infinite;
  animation: fadein 2s infinite;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Standard syntax */
@keyframes zoom {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

:root {
  --primeWeb: #4a521d;
  --darkBlack: #030303;
  --mediumGrey: #424242;
  --lightGrey: #f3f3f3;
  --hardWhite: #fefefe;
  --lightshade: #d9dfbb;
  --errorRedGroud: #ffa7a7;
  --table-bg-color: #fff;
  --table-even-row-bg-color: #f2f2f2;
  --table-header-bg-color: #f2f2f2;
  --table-txt-color: #333;
  --table-border-color: #ddd;
  --box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  --card-heading-color: #000;
  --card-content-color: #555;
}

html,
body {
  height: 100%;
  font-family: "Poppins", sans-serif;
}

.font-monst {
  font-family: "Poppins", sans-serif;
}

.font-monst-bold {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

:root {
  --swiper-theme-color: #4a521d !important;
}

.loader-container {
  display: flex;
  align-items: center;
  height: 80vh;
  justify-content: center;
}

.copyrightBar {
  background-color: #3e3e3e;
  color: white;
  text-align: center;
  padding: 10px 45px;
  font-size: 0.9rem;
}

.page-header {
  height: 200px;
  position: relative;
  opacity: 0.9;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.center-heading {
  position: absolute;
  left: 0;
  top: 40%;
  width: 100%;
  text-align: center;
  color: var(--darkBlack);
  font-size: 2rem;
  font-weight: bold;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes zoominout {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes zoominout {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes zoominout {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-ms-keyframes zoominout {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUpFade {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 818px) {
  .long-menu {
    display: none;
  }

  .drawer {
    display: block;
  }

  .bottom-navigation {
    display: block;
  }

  .base-container {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 819px) {
  .drawer {
    display: none;
  }

  .long-menu {
    display: block;
  }

  .bottom-navigation {
    display: none;
  }
}

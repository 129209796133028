:root {
  --header-bg-color: #ffffff;
  --header-text-color: #222;
  --header-hover-color: #4a521d;
}

.MuiDrawer-paper {
  background: #ffffff !important;
  width: 175px;
}

.header-avatar {
  font-family: "Poppins", sans-serif !important;
  background-color: var(--primeWeb) !important;
  font-size: 1.4rem !important;
  font-weight: bold;
  color: #ffffff !important;
}

.otp-error {
  text-align: center;
  margin: 5px;
  padding: 3px;
  background-color: #ff9b94;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 5px;
  animation: fadein ease 0.5s;
}

.drawer-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  -webkit-animation: fadein ease 0.5s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein ease 0.5s;
  /* Firefox < 16 */
  -ms-animation: fadein ease 0.5s;
  /* Internet Explorer */
  -o-animation: fadein ease 0.5s;
  /* Opera < 12.1 */
  animation: fadein ease 0.5s;
}

.swipe-avatar {
  width: 60px !important;
  height: 60px !important;
  margin: 5px !important;
  background-color: #4a521d !important;
  color: #ffffff !important;
  font-size: 2rem !important;
  font-weight: bold !important;
  font-family: "Poppins", sans-serif !important;
}

.mobile-otp-btn {
  margin: 5px 0px !important;
}

.mobile-otp-btn:hover {
  /* margin: 5px 0px;
  padding: 5px; */
  color: #4a521d;
}

.header-btn {
  color: #000;
  text-decoration: none;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin: 10px 0;
}

/* .header-btn:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
} */

.login-dialog-title h2 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}

.login-dialog-text {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}

.lrd-dialog .MuiDialogContent-root {
  padding: 0px !important;
}

.lrd-dialog-wrapper {
  display: flex;
  /* align-items: center; */
}

.lrd-left-panel {
  -webkit-animation: fadein ease 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein ease 1s;
  /* Firefox < 16 */
  -ms-animation: fadein ease 1s;
  /* Internet Explorer */
  -o-animation: fadein ease 1s;
  /* Opera < 12.1 */
  animation: fadein ease 1s;
}

.lrd-right-panel {
  margin: 10px;
  text-align: center;
  -webkit-animation: fadein ease 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein ease 1s;
  /* Firefox < 16 */
  -ms-animation: fadein ease 1s;
  /* Internet Explorer */
  -o-animation: fadein ease 1s;
  /* Opera < 12.1 */
  animation: fadein ease 1s;
}

.lrd-header {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lrd-tabs-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .lrd-tabs-wrapper .Mui-selected {
  background-color: #ffd36d;
  color: #000;
  font-weight: bold;
} */

.lrd-active-tab {
  padding: 8px;
  margin: 5px;
  background: #4a521d;
  border-radius: 5px;
  border-bottom: 2px solid #000;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.9rem;
}

.lrd-tab {
  padding: 8px;
  margin: 5px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.9rem;
}

.lrd-login-form {
  margin: 15px;
  -webkit-animation: fadein ease 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein ease 1s;
  /* Firefox < 16 */
  -ms-animation: fadein ease 1s;
  /* Internet Explorer */
  -o-animation: fadein ease 1s;
  /* Opera < 12.1 */
  animation: fadein ease 1s;
}

.text-field-margin {
  margin: 15px 0px !important;
}

.lrd-register-form {
  margin: 15px;
  -webkit-animation: fadein ease 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein ease 1s;
  /* Firefox < 16 */
  -ms-animation: fadein ease 1s;
  /* Internet Explorer */
  -o-animation: fadein ease 1s;
  /* Opera < 12.1 */
  animation: fadein ease 1s;
}

.lrd-heading {
  font-weight: bold;
  font-size: 1.3rem;
  margin: 10px;
}

.lrd-subheading {
  margin: 10px 0px;
  font-weight: bold;
}

.login-error {
  font-size: 0.85rem;
  font-weight: bold;
  color: #ff0000;
  padding: 5px;
  margin: 10px 0px;
  border-radius: 5px;
  background: #f9c7c3;
}

.annc-image {
  width: 100%;
  max-width: 400px;
  display: block;
  margin: auto;
  -webkit-animation: fadein ease 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein ease 1s;
  /* Firefox < 16 */
  -ms-animation: fadein ease 1s;
  /* Internet Explorer */
  -o-animation: fadein ease 1s;
  /* Opera < 12.1 */
  animation: fadein ease 1s;
}

.annc-items {
  margin: 10px;
  font-size: 1.1rem;
  font-weight: bold;
}

.resentLinkTick {
  margin: 20px 0px;
  -webkit-animation: fadein ease 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein ease 1s;
  /* Firefox < 16 */
  -ms-animation: fadein ease 1s;
  /* Internet Explorer */
  -o-animation: fadein ease 1s;
  /* Opera < 12.1 */
  animation: fadein ease 1s;
}

.bottom-navigation {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
}

.MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly {
  padding-top: 0px !important;
}

.footer-avatar {
  width: 35px !important;
  height: 35px !important;
  font-family: "Poppins", sans-serif !important;
  background-color: #4a521d !important;
  font-size: 1.2rem !important;
  font-weight: bold;
  color: #ffffff !important;
}

.search-fab-btn {
  /* position: fixed;
  bottom: 60px;
  right: 10px;
  z-index: 5; */
  background-color: #4a521d;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;
}

.contact-fab-btn {
  position: fixed;
  bottom: 60px;
  left: 10px;
  z-index: 5;
  background-color: #25d366;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #25d366;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

.search-header {
  background: #4a521d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.search-heading {
  font-weight: bold;
  font-size: 1.2rem;
}

.search-text-feild {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.search-list-item {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* flex-wrap: wrap; */
  background: #eee;
  width: 90%;
  margin: 15px auto;
  /* margin-bottom: 15px; */
  border-radius: 10px;
}

.search-list-image {
  max-width: 200px;
  min-width: 130px;
  margin: 10px;
  width: 40%;
}

.search-item-details {
  margin-right: 10px;
}

.search-item-name {
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 10px;
}

.search-cat-tags {
  background: #4a521d;
  margin: 10px 0px;
  padding: 5px;
  font-size: 0.9rem;
  display: inline-block;
  font-weight: bold;
  border-radius: 5px;
}

.search-product-description {
  text-align: justify;
  margin: 10px 0px;
  margin-right: 15px;
}

.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 0.4s ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 0.4s ease-in;
}

.item-appear {
  opacity: 0;
}

.item-appear.item-appear-active {
  opacity: 1;
  transition: opacity 0.4s ease-in;
}

.item-not-found {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.item-not-found-image {
  /* width: 50%; */
  width: 50%;
  max-width: 450px;
  min-width: 325px;
  animation: zoominout 4s infinite;
}

.item-not-found-heading {
  text-align: center;
  margin-top: 10px;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

@media only screen and (max-width: 599px) {
  .lrd-left-panel {
    display: none;
  }
}

@media only screen and (max-width: 525px) {
  .search-product-description {
    display: none;
  }
}

.header-container {
  display: flex;
  width: 100%;
}

.header-title {
  flex-grow: 1;
  display: flex;
  align-items: center;
  div {
    cursor: pointer;
  }
}

.header-navigation {
  display: flex;
}

.mobile-login {
  display: none;
}

@media screen and (max-width: 818px) {
  .header-navigation {
    display: none;
  }

  .bottom-navigation {
    display: block;
  }

  .mobile-login {
    color: var(--header-text-color);
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
}

.header-navigation-item {
  padding: 10px;
  color: var(--header-text-color);
  cursor: pointer;
  font-weight: bold;
  user-select: none;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  color: var(--header-hover-color) !important;
  font-size: 1.3rem !important;
}

.header-navigation-item:hover {
  color: var(--header-hover-color);
}

.auth-container {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
}

.auth-content-wrapper {
  width: 45%;
  padding: 2.5%;
  text-align: center;
}

.auth-dialog-title {
  padding: 10px 0px;
  font-size: 1.2rem;
  font-weight: bold;
}

.auth-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.register-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.api-error {
  font-weight: bold;
  font-size: 1rem;
  color: #e31e24;
}

@media screen and (max-width: 600px) {
  .auth-login-image {
    display: none;
  }

  .auth-content-wrapper {
    width: 90%;
    padding: 5%;
  }
}

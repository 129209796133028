#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  font-family: "Poppins", sans-serif !important;
}

.component-container {
  /* height: 100%; */
  margin-top: 64px;
  min-height: 100%;
}

/* IMAGES */

img {
  overflow-clip-margin: content-box;
  overflow: clip;
  width: 100%;
}

/* TABLES */

table {
  border-collapse: collapse;
  width: 100%;
  background-color: var(--table-bg-color);
  border: 1px solid var(--table-border-color);
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: var(--table-header-bg-color);
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: var(--table-even-row-bg-color);
}

table td {
  padding: 16px 12px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  color: var(--table-txt-color);
  border-bottom: 1px solid var(--table-border-color);
}

table th {
  padding: 16px 12px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  color: var(--table-txt-color);
  border-bottom: 1px solid var(--table-border-color);
}

/* Card */

.card {
  width: 25%;
  padding: 20px;
  margin: 10px;

  border-radius: 5px;
  box-shadow: var(--box-shadow);
}

@media only screen and (max-width: 820px) and (min-width: 500px) {
  .card {
    width: 38%;
  }
}

@media only screen and (max-width: 499px) {
  .card {
    width: 70%;
  }
}

.card ul {
  list-style: none;
  padding: 0;
}

.card ul li:first-child {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 10px;
  margin: 0;

  color: var(--card-heading-color);
}

.card ul li {
  color: var(--card-content-color);
  font-size: 1rem;
  margin-left: 10px;
  word-break: break-word;
}

.MuiTypography-root {
  font-family: "Poppins", sans-serif !important;
}

.MuiMenuItem-root {
  font-family: "Poppins", sans-serif !important;
}

h1 {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
}

h2 {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
}

h3 {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}

h4 {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

h5 {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

p {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}
